import React, { Component } from 'react';
import jstz from 'jstz';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { MDCSelect } from '@material/select';
import { MDCTextField } from '@material/textfield';

import Image from '../image';
import Captcha from '../captcha';

import { PageContext } from '../../context/page-context';
import cookieManager from '../../utils/cookie';
import { gdprSet } from '../../utils/gdpr';
import { generateSrcSet } from '../../utils/breakpoints';
import { countries as signUpCountries } from '../../utils/sign-up-countries';
import { PasswordRequirements, TestPasswordRequirements } from '../ui/password-requirements';
import { Cookie } from '../../../lib/constants';

const categories = {
    1: 'Everything else!',
    2: 'Hair',
    3: 'Beauty',
    4: 'Massage/Day Spa',
    6: 'Health/Wellbeing Clinic',
    7: 'Tutoring/Consulting',
    12: 'Hair & Beauty',
    13: 'Barber',
    14: 'Body Art',
    15: 'Personal Training',
};

const keys = ['13', '3', '14', '1', '2', '12', '6', '4', '15', '7'];

class RenderTrial extends Component {
    state = {
        form: {
            password: '',
        },
    };

    fields = [];

    formFields = [
        'fname',
        'lname',
        'tel',
        'email',
        'businessSize',
        'businessCategory',
        'countryCode',
        'currencyCode',
        'referrer_business_id',
        'timely-user',
        'password',
    ];

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        const selectElements = [].slice.call(
            document.querySelectorAll('.mdc-select')
        );

        selectElements.forEach(selectEl => {
            new MDCSelect(selectEl);
        });

        const textFieldElements = [].slice.call(
            document.querySelectorAll('.mdc-text-field')
        );

        textFieldElements.forEach(textFieldEl => {
            const input = textFieldEl.querySelectorAll('input');

            this.fields[input[0].name] = new MDCTextField(textFieldEl);
        });

        setTimeout(() => {
            this.getPersonalInfo();
            gdprSet();
        }, 400);
    }

    getPersonalInfo() {
        const { form } = this.state;

        const newForm = Object.assign({}, form);
        // hack to work w current timezone info
        // https://medium.com/@jonathanabrams/be-aware-of-browsers-internationalization-api-db94bb32f9a8
        const oldIntl = window.Intl;

        window.Intl = undefined;
        const timeZone = jstz.determine().name();
        window.Intl = oldIntl;

        this.formFields.forEach(field => {
            if (this.context[field]) {
                newForm[field] = this.context[field];
            }
        });

        axios
            .get(
                `https://app.gettimely.com/Register/GetTimeZoneIdByOlsonName?olsonName=${timeZone}`
            )
            .then(({ data }) => {
                if (data.Id && data.Id !== 0) {
                    newForm.time_zone_locale_id = data.Id.toString();
                }

                if (data.Id && data.Id == 0) {
                    Sentry.captureMessage(
                        `Sign-up: Users timeZone_id was 0 and their timeZone was ${timeZone}`
                    );
                }
            })
            .catch(err => {
                Sentry.captureException(err);
            })
            .then(() => {
                this.setState({
                    form: newForm,
                });
            });
    }

    onCaptchaChange(value) {
        const { form } = this.state;
        form.captcha = value;

        this.setState(form);
    }

    handleInputChange(event) {
        const { form } = this.state;

        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newForm = Object.assign({}, form, { [name]: value });

        this.setState({
            form: newForm,
        });
    }

    renderTextField(label, id) {
        const { form } = this.state;
        const labelClass =
            form[id] && form[id].length > 0
                ? 'mdc-floating-label mdc-floating-label--float-above'
                : 'mdc-floating-label';

        return (
            <div>
                <div className="mdc-text-field mdc-text-field--fullWidth ">
                    <input
                        onChange={this.handleInputChange}
                        name={id}
                        defaultValue={form[id]}
                        className="mdc-text-field__input"
                        required
                    />
                    <label className={labelClass}>{label}</label>
                </div>
                <div className="mdc-text-field-helper-line">
                    <p className="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
                        This field is required
                    </p>
                </div>
            </div>
        );
    }

    submitTrial = e => {
        e.preventDefault();
        const { geolocation, setLocalStorage } = this.context;
        const { form } = this.state;
        let {
            config: { countries },
        } = this.props.block;

        if (!TestPasswordRequirements(this.state.form.password)) {
            return false;
        }

        this.setState({
            showLoading: true,
        });

        const sendForm = new FormData();
        for (const property in form) {
            let label = property;
            label = property === 'fname' ? 'firstName' : label;
            label = property === 'lname' ? 'lastName' : label;
            label = property === 'tel' ? 'phone' : label;

            sendForm.append(label, form[property]);
        }

        sendForm.append('action', 'sign_up_ajax');

        const timelyReseller = cookieManager.get(Cookie.TIMELY_RESELLER);
        if (timelyReseller) {
            sendForm.append('reseller_code', timelyReseller);
        }

        const timelyReferrer = cookieManager.get(Cookie.TIMELY_REFERRER);
        if (timelyReferrer) {
            sendForm.append('referrer_business_id', timelyReferrer);
        }

        if (!countries) {
            countries = signUpCountries;
        }

        countries.find(country => {
            if (country.country_code === geolocation) {
                sendForm.append('currencyCode', country.currency_code);
            }
        });

        sendForm.append('countryCode', geolocation);

        const { timber_ajax_obj } = window;
        if (typeof timber_ajax_obj !== 'undefined') {
            axios
                .post(timber_ajax_obj.ajaxurl, sendForm)
                .then(res => {
                    const { data } = res;

                    if (data.login_url) {
                        form['timely-user'] = 'yes';
                        // TODO: agree on whether we should store context first or LS
                        setLocalStorage(form);
                        window.location = data.login_url;
                    }

                    if (data.message) {
                        let error =
                            'There was an issue with the connection please try again later';

                        if (
                            data.message ===
                            'A Timely login with this email address already exists. Please use another email address to continue signing up.'
                        ) {
                            error =
                                'Looks like an account with this email address already exists. Please <a class="Button Button--link" href="https://app.gettimely.com/calendar">click here to login</a>';
                        }
                        this.setState({
                            serverError: data.message,
                            error,
                            isDisabled: false,
                            submitLabel: 'Start my trial',
                        });
                        // setLocalStorage(form);
                        Sentry.captureException(error);
                        Sentry.captureMessage(`Brochure Sign up Form Error, ${error}`);

                    }
                })
                .catch(err => {
                    this.setState({
                        serverError: err.message,
                        error:
                            'There was an issue with the connection please try again later',
                        isDisabled: false,
                        submitLabel: 'Start my trial',
                    });
                    Sentry.captureException(err);
                    Sentry.captureMessage(`Brochure Sign up Form Error, ${err}`);
                })
                .then(res => {
                    this.setState({
                        showLoading: false,
                        showSuccess: true,
                    });
                });
        }
    };

    renderLoading() {
        const { content } = this.props.block;

        return (
            <div
                data-testid="Brochure-loading"
                className="Brochure-container container">
                <div className="Brochure-col1 col-md-4 col-md-offset-1 col-xs-6 col-sm-6">
                    <img
                        src={content.image.src}
                        className="Brochure-image"
                        alt={content.image.alt}
                    />
                </div>
                <div className="Brochure-col2 col-xs-12 col-sm-10 col-md-5 col-md-offset-1">
                    <div className="Brochure-form-wrap">
                        <h3>Setting up your 14-day free trial now</h3>
                        <div className="Brochure-loading">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { content } = this.props.block;
        const { error } = this.state;
        // const passwordErrorLabel = 'Please provide a secure password';

        const { forceCaptcha } = this.context;

        const { form, showLoading } = this.state;

        if (showLoading) {
            return this.renderLoading();
        }

        const { srcSet, sizes } = generateSrcSet(50, content.image.src);

        return (
            <div
                className="Brochure-container container"
                data-testid="BrochureTrialForm">
                <div className="Brochure-col1 col-md-offset-1 col-md-4 col-xs-12 col-sm-6 u-textCenter">
                    <div className="row mb2">
                        <div className="col-xs-6 col-xs-offset-3">
                            <Image
                                block={{ content: content.image }}
                                className="Brochure-image"
                                srcSet={srcSet}
                                sizes={sizes}
                            />

                        </div>
                    </div>
                    <h3>{content.title}</h3>
                    <p>
                        <small>
                            <a
                                href={content.download}
                                className="Button Button--white"
                                target="_blank"
                                rel="noopener noreferrer">
                                {content['submit-label']}
                            </a>
                        </small>
                    </p>
                </div>
                <div className="Brochure-col2 Brochure-col2--divider col-xs-12 col-sm-10 col-md-4 col-md-offset-1">
                    <div className="Brochure-form-wrap">
                        <h3>Why not start a free trial while you’re here?</h3>

                        {error && (
                            <div
                                className="Message Message--error"
                                dangerouslySetInnerHTML={{
                                    __html: error,
                                }}></div>
                        )}
                        <form
                            data-testid="BrochureForm-trial"
                            data-name="timely-brochure"
                            name="timely-brochure"
                            className="Brochure-form"
                            onSubmit={this.submitTrial}>
                            <div className="mt1 SignUp-row--two-col SignUp-row row">
                                <div className="col-xs-12 col-sm-6">
                                    {this.renderTextField(
                                        'First name',
                                        'fname'
                                    )}
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    {this.renderTextField('Last name', 'lname')}
                                </div>
                            </div>
                            <div className="SignUp-row row">
                                <div className="col-xs-12">
                                    <div>
                                        <div className="mdc-text-field mdc-text-field--fullWidth ">
                                            <input
                                                onChange={
                                                    this.handleInputChange
                                                }
                                                defaultValue={form['email']}
                                                name="email"
                                                type="email"
                                                className="mdc-text-field__input"
                                                required
                                            />
                                            <label
                                                className={
                                                    form['email'] &&
                                                        form['email'].length > 0
                                                        ? 'mdc-floating-label mdc-floating-label--float-above'
                                                        : 'mdc-floating-label'
                                                }>
                                                Email
                                            </label>
                                        </div>
                                        <div className="mdc-text-field-helper-line">
                                            <p className="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
                                                This field is required
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-row row">
                                <div className="col-xs-12">
                                    <div className="mdc-text-field mdc-text-field--fullWidth ">
                                        <input
                                            type="tel"
                                            name="tel"
                                            pattern="[0-9]*"
                                            data-testid="tel"
                                            defaultValue={form['tel']}
                                            onInvalid={e => {
                                                e.target.setCustomValidity(
                                                    'Please enter only numbers'
                                                );
                                            }}
                                            onChange={e => {
                                                this.handleInputChange(e);
                                                try {
                                                    e.target.setCustomValidity(
                                                        ''
                                                    );
                                                } catch (e) {
                                                    // Continue regardless of error
                                                }
                                            }}
                                            className="mdc-text-field__input"
                                            required
                                        />
                                        <label
                                            className={
                                                form['tel'] &&
                                                    form['tel'].length > 0
                                                    ? 'mdc-floating-label mdc-floating-label--float-above'
                                                    : 'mdc-floating-label'
                                            }>
                                            Phone
                                        </label>
                                    </div>
                                    <div className="mdc-text-field-helper-line">
                                        <p className="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
                                            This field is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-row row">
                                <div className="col-xs-12">
                                    <div className="mdc-select mdc-select--outlined Brochure-select">
                                        <i className="mdc-select__dropdown-icon fa fa-angle-up fa-angle-down"></i>
                                        <select
                                            name="businessSize"
                                            data-testid="businessSize"
                                            value={form['businessSize'] || ''}
                                            onChange={this.handleInputChange}
                                            className="mdc-select__native-control"
                                            required>
                                            <option value="" disabled></option>
                                            <option value="1">Solo</option>
                                            <option value="2">
                                                2 - 7 staff
                                            </option>
                                            <option value="3">
                                                8 - 20 staff
                                            </option>
                                            <option value="4">
                                                Over 20 staff
                                            </option>
                                        </select>
                                        <div className="mdc-notched-outline">
                                            <div className="mdc-notched-outline__leading"></div>
                                            <div className="mdc-notched-outline__notch">
                                                <label
                                                    className={
                                                        form['businessSize']
                                                            ? 'mdc-floating-label mdc-floating-label--float-above'
                                                            : 'mdc-floating-label'
                                                    }>
                                                    Team size
                                                </label>
                                            </div>
                                            <div className="mdc-notched-outline__trailing"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="SignUp-row row">
                                <div className="col-xs-12">
                                    <div className="mdc-select mdc-select--outlined Brochure-select">
                                        <i className="mdc-select__dropdown-icon fa fa-angle-up fa-angle-down"></i>
                                        <select
                                            name="businessCategory"
                                            data-testid="businessCategory"
                                            value={
                                                form['businessCategory'] || ''
                                            }
                                            onChange={this.handleInputChange}
                                            className="mdc-select__native-control"
                                            required>
                                            <option value="" disabled></option>
                                            {keys &&
                                                keys.map(key => {
                                                    if (key == 1) { return null; }
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={key}>
                                                            {categories[key]}
                                                        </option>
                                                    );
                                                })}
                                            <option key="1" value="1">
                                                Everything else!
                                            </option>
                                        </select>
                                        <div className="mdc-notched-outline">
                                            <div className="mdc-notched-outline__leading"></div>
                                            <div className="mdc-notched-outline__notch">
                                                <label
                                                    className={
                                                        form['businessCategory']
                                                            ? 'mdc-floating-label mdc-floating-label--float-above'
                                                            : 'mdc-floating-label'
                                                    }>
                                                    Business Type
                                                </label>
                                            </div>
                                            <div className="mdc-notched-outline__trailing"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="SignUp-row row">
                                <div className="col-xs-12">
                                    <div className="mdc-select mdc-select--outlined Brochure-select">
                                        <i className="mdc-select__dropdown-icon fa fa-angle-up fa-angle-down"></i>
                                        <select
                                            name="timely-user"
                                            onChange={this.handleInputChange}
                                            data-testid="timely-user"
                                            value={form['timely-user'] || ''}
                                            className="mdc-select__native-control"
                                            required>
                                            <option value="" disabled></option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        <div className="mdc-notched-outline">
                                            <div className="mdc-notched-outline__leading"></div>
                                            <div className="mdc-notched-outline__notch">
                                                <label
                                                    className={
                                                        form['timely-user']
                                                            ? 'mdc-floating-label mdc-floating-label--float-above'
                                                            : 'mdc-floating-label'
                                                    }>
                                                    Do you use Timely?
                                                </label>
                                            </div>
                                            <div className="mdc-notched-outline__trailing"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <input
                                className="field granite"
                                data-testid="main-source"
                                name="main-source"
                                readOnly
                                defaultValue="Content"
                                hidden
                            />
                            <input
                                className="field granite"
                                data-testid="sub-source"
                                name="sub-source"
                                readOnly
                                defaultValue="Content"
                                hidden
                            />
                            <input
                                className="field granite"
                                data-testid="source"
                                name="source"
                                readOnly
                                defaultValue={content['lead-source']}
                                hidden
                            />
                            <input
                                className="field"
                                name="download"
                                defaultValue={content.download}
                                hidden
                            />

                            <div className="SignUp-row row">
                                <div className="col-xs-12">
                                    {this.renderTextField(
                                        'Business name',
                                        'businessName'
                                    )}
                                </div>
                            </div>
                            <div className="SignUp-row row">
                                <div className="col-xs-12">
                                    <div className="mdc-text-field mdc-text-field--fullWidth">
                                        <input
                                            name="password"
                                            className="mdc-text-field__input"
                                            minLength="8"
                                            onChange={this.handleInputChange}
                                            type="password"
                                            required
                                        />
                                        <label
                                            className={
                                                form['password']
                                                    ? 'mdc-floating-label mdc-floating-label--float-above'
                                                    : 'mdc-floating-label'
                                            }>
                                            Password
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {this.state.form.password.length > 0 && (
                                <div className="SignUp-row row">
                                    <div className="col-xs-12">
                                        <PasswordRequirements password={this.state.form.password} />
                                    </div>
                                </div>
                            )}
                            <label className="Brochure-tnc Checkbox">
                                Send me Timely news and marketing offers
                                <input
                                    type="checkbox"
                                    onChange={this.handleInputChange}
                                    name="newsoffers"
                                    data-testid="newsoffers"
                                    defaultValue="yes"
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="Brochure-privacy Checkbox Privacy-checkbox">
                                By providing your information, you agree to our{' '}
                                <a href="/support/privacy">privacy policy</a>
                                <input
                                    type="checkbox"
                                    name="privacy"
                                    data-testid="privacy"
                                    defaultValue="yes"
                                    required
                                />
                                <span className="checkmark"></span>
                            </label>
                            {forceCaptcha && (
                                <div className="mt2">
                                    <Captcha onChange={this.onCaptchaChange} />
                                </div>
                            )}
                            <div className="Brochure-actions">
                                <input
                                    type="submit"
                                    className="Button Button--primary"
                                    value="Start a free trial"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

RenderTrial.contextType = PageContext;

export default RenderTrial;
