import React from 'react';
import { useEffect, useState } from 'react';
import { marketoSetCountries } from '../../utils/marketo-set-countries';

// lead_source field
// This name, ID pair inserts a Marketo form that the downloadable file is attached to. They are hosted on go.gettimely.com and require an employee with Marketo access to update

const formIDs = {
    'Buyers Guide generic': 1009,
    'Buyers Guide': 1022,
    'SMS Marketing Guide': 1062,
    'influencer marketing guide': 1014,
    'engaged-team': 1056,
    'Salon Profitability': 1016,
    'salon-trends-2020-vol-1': 1018,
    'salon-trends-2020-vol-2': 1019,
    'salon-trends-2020-vol-3': 1020,
    '40-promo-ideas': 1031,
    'client-experience': 1044,
    'revenue-protection-guide': 1039,
    'build-community': 1051,
    'avatar': 1012,
    'insta-worthy': 1054
};


export default function RenderFields({ block, setFields, context }) {
    const [ID, setID] = useState(null);
    const { content } = block;


    useEffect(() => {
        setTimeout(() => {
            if (content['lead-source'] && typeof formIDs[content['lead-source']] !== 'undefined' && ID == null) {
                setID(formIDs[content['lead-source']]);
            }

            const { MktoForms2 } = window;
            if (typeof MktoForms2 !== 'undefined' && ID != null) {
                MktoForms2.loadForm("//go.gettimely.com", "304-RPU-875", ID, (form) => {
                    marketoSetCountries(context);
                    // console.log('has rendered');

                    form.onSuccess(function (values, followUpUrl) {
                        // console.log('has submitted');
                        // Get the form's jQuery element and hide it

                        // Display followup message in place of the form

                        if (document.getElementById("Is_Timely_User__c").value != null) {
                            setFields({ 'timely-user': document.getElementById("Is_Timely_User__c").value });
                        }

                        form.getFormElem().hide();
                        // Direct lead to followup url in new tab
                        window.open(followUpUrl, '_blank');
                        return false;
                    });


                });
            }
        }, 200);

    }, [ID]);

    return (
        <div className="Brochure-container container">
            <div className="Brochure-col1 col-md-4 col-md-offset-1 col-xs-6 col-sm-6">
                <img
                    src={content.image.src}
                    className="Brochure-image"
                    alt={content.image.alt}
                />
            </div>
            <div className="Brochure-col2 col-xs-12 col-sm-10 col-md-5 col-md-offset-1">
                <div className="Brochure-form-wrap">
                    <h3>{content.title}</h3>
                    <p>{content.description}</p>
                    <form id={`mktoForm_${ID}`}></form>
                </div>
            </div>
        </div>
    );
}
