export const BreakPoints = [767, 992, 1200, 1920];

export const generateSrcSet = function (width, src) {
  let srcSet = ``;
  let sizes = ``;
  if (!src || typeof src === 'object' || src.indexOf(".svg") !== -1) {
    return { srcSet, sizes };
  }

  // if (
  //     ![
  //         'www.gettimely.com',
  //         'gettimely.local',
  //         // 'stage.gettimely.com',
  //         // 'timelystage.wpengine.com',
  //     ].includes(window.location.hostname)
  // ) {
  //     return { srcSet, sizes };
  // }

  BreakPoints.forEach((bp, i) => {
    const isLast = BreakPoints.length === i + 1;
    const separator = isLast ? "" : ", ";

    const srcWidth = Math.ceil((bp * width) / 100);
    const hostname = process.env.NEXT_PUBLIC_ROOTURL === 'https://www.gettimely.com/' ? 'https://www.gettimely.com' : 'https://stage.gettimely.com';
    // const imgSrc = new URL(src).pathname;
    src = src.replace("gettimely.local", "");
    src = src.replace("www.gettimely.com", "");
    src = src.replace("timelystage.gettimely.com", "");
    src = src.replace("https://", "");
    src = src.replace("http://", "");

    // https://developers.cloudflare.com/images/image-resizing/url-format/
    const newSrc = `${hostname}/cdn-cgi/image/w=${srcWidth},f=auto${src}`;

    srcSet += `${newSrc} ${srcWidth}w${separator}`;
    if (isLast) {
      sizes += `${srcWidth}px`;
    } else {
      sizes += `(max-width: ${bp}px) ${srcWidth}px${separator}`;
    }
  });

  // console.log(generateSrcSet, srcSet, sizes);
  return { srcSet, sizes };
};
