import React from 'react';

const requirements = [
  {
    label: 'Minimum 8 characters',
    regularExpression: new RegExp('.{8,}'),
  },
  {
    label: 'One lowercase letter',
    regularExpression: new RegExp('[a-z]'),
  },
  {
    label: 'One uppercase letter',
    regularExpression: new RegExp('[A-Z]'),
  },
  {
    label: 'One number or special character',
    regularExpression: new RegExp('\\W|\\d'),
  },
];

export const TestPasswordRequirements = value => {
  let passed = true;
  requirements.forEach(requirement => {
    if (!requirement.regularExpression.test(value)) {
      passed = false;
    }
  });

  return passed;
};

export const PasswordRequirements = ({ password }) => {
  const allPassed = TestPasswordRequirements(password);

  if (allPassed) {
    return (
      <div className="password-requirements">
        <div className="alert alert-success">
          Your password meets requirements.
        </div>
      </div>
    );
  }

  return (
    <div className="password-requirements">
      <div className="password-requirements__label">Your password must contain:</div>

      <div className="password-requirements__list">
        {requirements.map((requirement, index) => {
          const passed = requirement.regularExpression.test(password);

          return (
            <div className="password-requirement__line" key={index}>
              {passed && (
                <div className="password-requirement__line-status password-requirement__line-status--pass">
                  <svg width={12} height={12}>
                    <circle fill="#02CFAD" cx={6} cy={6} r={6} fillRule="evenodd" />
                    {/* #02CFAD */}
                  </svg>

                </div>
              )}

              {!passed && (
                <div className="password-requirement__line-status password-requirement__line-status--fail">
                  <svg width={12} height={12}>
                    <circle fill="#889095" cx={6} cy={6} r={6} fillRule="evenodd" />
                    {/* #888 */}
                  </svg>
                </div>
              )}

              {requirement.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

