import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { MDCTextField } from '@material/textfield';
import { MDCSelect } from '@material/select';
import { PageContext } from '../context/page-context';

import RenderFields from './brochure/render-fields';
import RenderTrial from './brochure/render-trial';
import countries from '../utils/country-list';
import imagePropType from '../prop-types/image';

const categories = {
    1: 'Everything else!',
    2: 'Hair',
    3: 'Beauty',
    4: 'Massage/Day Spa',
    6: 'Health/Wellbeing Clinic',
    7: 'Tutoring/Consulting',
    12: 'Hair & Beauty',
    13: 'Barber',
    14: 'Body Art',
    15: 'Personal Training',
};
class Brochure extends React.Component {
    state = {
        form: {},
        showSignup: false,
        showSuccess: false,
    };

    formFields = [
        'fname',
        'lname',
        'tel',
        'email',
        'businessSize',
        'businessCategory',
        'businessName',
        'timely-user',
    ];

    fields = [];

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setFields = this.setFields.bind(this);
        this.initializeFields = this.initializeFields.bind(this);
        this.getPersonalInfo = this.getPersonalInfo.bind(this);
        this.showRenderState = this.showRenderState.bind(this);

    }

    componentDidMount() {
        setTimeout(() => {
            this.initializeFields();
            this.getPersonalInfo();
            this.showRenderState();
        }, 1800);
    }

    getPersonalInfo() {
        const { form } = this.state;
        const {
            config: { countries },
        } = this.props.block;
        const { geolocation } = this.context;

        const newForm = Object.assign({}, form);

        this.formFields.forEach(field => {
            if (this.context[field]) {
                newForm[field] = this.context[field];
            }
        });

        if (countries) {
            countries.find(country => {
                if (country.country_code === geolocation) {
                    newForm.currencyCode = country.currency_code;
                }
            });
        }

        this.setState({ form: newForm });
    }

    setFields(fields) {
        const { setPageContext } = this.context;
        const { form } = this.state;
        // const { content } = this.props.block;

        const newForm = Object.assign({}, form, fields);

        // setLocalStorage(newForm);

        this.setState({
            form: newForm,
            // showSignup: true,
        });

        for (const field in newForm) {
            setPageContext([field], newForm[field], { ...newForm, ...this.context });
        }

        setTimeout(() => {
            this.showRenderState();
        }, 400);

        // const autopilotData = this.generateAutopilotData(newForm);
        // this.submitAutopilotData(autopilotData);

        // window.open(content.download);
    }

    generateAutopilotData(form) {
        const { content } = this.props.block;
        const form_data = new FormData();
        let sessionId = '';

        let MailingCountry = 'United Kingdom';
        if (typeof countries[form.geolocation] !== 'undefined') {
            MailingCountry = countries[form.geolocation];
        }

        // TODO Test marketing GDPR
        let newsletter = false;
        if (document.querySelector('html').classList.contains('nogdpr')) {
            newsletter = 'yes';
        } else {
            newsletter = form.newsoffers == true ? 'yes' : 'no';
        }

        const { AutopilotAnywhere } = window;
        if (AutopilotAnywhere && AutopilotAnywhere.sessionId) {
            sessionId = AutopilotAnywhere.sessionId;
        }

        let BusinessCategory = categories[form.businessCategory];
        BusinessCategory = BusinessCategory.replace('&', 'and');

        let BusinessSize = 'Solo';
        switch (form.businessSize) {
            case '2':
                BusinessSize = '2 - 7 Staff';
                break;
            case '3':
                BusinessSize = '8 - 20 Staff';
                break;
            case '4':
                BusinessSize = 'Over 20 staff';
                break;
            case '1':
            default:
                BusinessSize = 'Solo';
                break;
        }

        const IsTimelyUser = form['timely-user'] === 'yes' ? true : false;

        form_data.append('action', 'submit_to_autopilot');

        form_data.append(
            'data',
            JSON.stringify({
                _autopilot_session_id: sessionId,
                contact: {
                    Email: form.email,
                    FirstName: form.fname,
                    LastName: form.lname,
                    Phone: form.tel,
                    MailingCountry,
                    custom: {
                        'string--BusinessCategory': BusinessCategory,
                        'string--Source_LatestMainSource': 'Content',
                        'string--Source_LatestSubSource': 'Content',
                        'string--Source_LatestSourceDetail':
                            content['lead-source'],
                        'string--FormField_Privacy': 'yes',
                        'string--FormField_Newsletter': newsletter,
                        'string--IndicatedBusinessSize': BusinessSize,
                        'boolean--IsTimelyUser': IsTimelyUser,
                    },
                },
            })
        );

        return form_data;
    }

    submitAutopilotData(autopilotData) {
        const { timber_ajax_obj } = window;
        if (typeof timber_ajax_obj !== 'undefined') {
            axios
                .post(timber_ajax_obj.ajaxurl, autopilotData)
                .then(res => { })
                .catch(err => {
                    Sentry.captureMessage(`Submit Gated content, ${err}`);
                });
        }
    }

    initializeFields() {
        const textFieldElements = [].slice.call(
            document.querySelectorAll('.mdc-text-field')
        );

        textFieldElements.forEach(textFieldEl => {
            const input = textFieldEl.querySelectorAll('input');
            this.fields[input[0].name] = new MDCTextField(textFieldEl);
        });

        const selectElements = [].slice.call(
            document.querySelectorAll('.mdc-select')
        );

        selectElements.forEach(selectEl => {
            new MDCSelect(selectEl);
        });
    }

    hasAccount() {
        const { business_status } = this.context;

        if (this.context['timely-user'] === 'yes') {
            return true;
        }

        return ['None', 'Active', 'TrialEnded', 'Trialing', 'Test'].includes(
            business_status
        );
    }

    handleInputChange(event) {
        const { form } = this.state;

        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newForm = Object.assign({}, form, { [name]: value });

        this.setState({
            form: newForm,
        });
    }

    // Has Account: Should render account
    // No Account but has LS: Should render sign up
    // No Account and No LS: Should render main form
    // Loading State: Should render loading
    showRenderState() {
        // Render views 'Form', 'Signup', 'Loading', 'Download'

        if (this.hasAccount()) {
            this.setState({
                renderView: 'Download',
            });
            return;
        }

        if (
            this.context['timely-user'] === 'no'
        ) {
            this.setState({
                renderView: 'Signup',
            });
            return;
        }

        this.setState({
            renderView: 'Form',
        });

        return;
    }

    renderActiveAccount() {
        const { content } = this.props.block;

        return (
            <div
                data-testid="BrochureActiveAccountView"
                className="Brochure-container container">
                <div className="Brochure-col1 col-md-4 col-md-offset-1 col-xs-6 col-sm-6">
                    <img
                        src={content.image.src}
                        className="Brochure-image"
                        alt={content.image.alt}
                    />
                </div>
                <div className="Brochure-col2 col-xs-12 col-sm-10 col-md-5 col-md-offset-1">
                    <div className="Brochure-form-wrap">
                        <div>
                            <h3>{content.title}</h3>
                            <p>{content.description}</p>
                            <p>
                                <small>
                                    <a
                                        href={content.download}
                                        className="Button Button--white"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {content['submit-label']}
                                    </a>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { config, content } = this.props.block;
        const { renderView } = this.state;
        // let renderState = this.showRenderState();

        const style = content['background-image']
            ? { backgroundImage: `url(${content['background-image']})` }
            : {};

        if (content.background) {
            style.backgroundColor = content.background;
        }

        // renderState = 'Signup';

        const className = config.className
            ? `Brochure ${config.className}`
            : 'Brochure';

        return (
            <div
                id="get-brochure"
                data-testid="get-brochure"
                name="get-brochure"
                className={className}
                style={style}>
                {renderView === 'Download' && this.renderActiveAccount()}

                {renderView === 'Signup' && (
                    <RenderTrial
                        block={this.props.block}
                        setFields={this.setFields}
                    />
                )}

                {renderView === 'Form' && (
                    <RenderFields
                        block={this.props.block}
                        context={this.context}
                        setFields={this.setFields}
                    />
                )}
            </div>
        );
    }
}

Brochure.propTypes = {
    block: PropTypes.shape({
        config: PropTypes.shape({
            verbose: PropTypes.bool,
        }),
        content: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            download: PropTypes.string,
            image: imagePropType,
            'lead-source': PropTypes.string,
            'submit-label': PropTypes.string,
        }),
    }),
};

Brochure.contextType = PageContext;

export default Brochure;
